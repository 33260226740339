import React from 'react'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import theme from './theme'

const SiteWrapper = ({ element }) => {
  return (
    <>
      <Global
        styles={css`
          *,
          *::after,
          *::before {
            box-sizing: inherit;
          }

          body {
            font-size: 16px;
            font-family: "OpenSans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
            box-sizing: border-box;
            margin: 0;
            color: #222;
          }

          p {
            margin-top: .5em;
            margin-bottom: .5em;
          }

          img {
            border: 0;
            max-width: 100%;
            height: auto;
          }
        `}
      />
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    </>
  )
}

export default SiteWrapper
