import galite from 'ga-lite'
import Boot from './src/Boot'
import './src/fonts.css'

let isTrackingInitialized = false

const isTrackingEnabled = () => !!process.env.GA_TRACKING_ID

const initializeTracking = () => {
  if (!isTrackingInitialized) {
    galite('create', process.env.GA_TRACKING_ID, 'auto')
    galite('set', 'anonymizeIp', true)

    isTrackingInitialized = true
  }
}

export const onRouteUpdate = () => {
  if (isTrackingEnabled()) {
    initializeTracking()
    galite('send', 'pageview')
  }
}

export const wrapRootElement = Boot
