import Color from 'color'

const brand = [
  '#7c4240',
  'rgba(117, 193, 71, .875)'
]

const theme = {
  colors: {
    background: '#fff',
    img: 'rgba(0, 0, 0, .5)',
    brand,
    secondary: {
      dark: 'rgba(17, 23, 33, .6)',
      light: 'rgba(255, 255, 255, .6)'
    },
    heading: {
      dark: '#0e385d',
      light: '#fff'
    },
    text: {
      default: 'rgba(0, 0, 0, .7)',
      dark: '#161414',
      light: '#fff',
    },
    bg: {
      dark: '#241414',
      gray: '#F7F7F7',
      light: '#fff',
      overlay: 'rgba(0, 0, 0, 0.3)'
    },
    divider: '#e4e4e4',
  },
  fonts: {
    body: '"OpenSans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif',
    heading: 'inherit',
  },
  fontSizes: {
    0: 12,
    1: 14,
    2: 16,
    3: 20,
    4: 24,
    5: 30,
    6: 30,
    7: 48,
    8: 64,
    9: 96
  },
  fontWeights: {
    body: 'normal',
    heading: 'bold',
    bold: 'bold'
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  sizes: {
  },
  radii: {
    card: 5,
    default: 4,
  },
  shadows: {
    overlay: '0 0 24px rgba(0, 0, 0, .4)',
    card: '0px 0px 30px 5px rgba(0,0,0,0.05)',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading'
    },
    caps: {
      textTransform: 'uppercase',
    },
    sectionHead: {
      fontFamily: 'PlayfairDisplayBold'
    },
  },
  variants: {
    link: {
      color: 'inherit',
      textDecoration: 'none',

      '&:hover, &:active, &.active': {
        color: brand[0]
      }
    },
    activeLink: {
      color: brand[0],
      fontWeight: 'bold'
    }
  },
  buttons: {
    brand: {
      py: 3,
      px: 2,
      fontSize: 1,
      fontWeight: 'bold',
      bg: 'brand.0',
      borderRadius: 'default',
      alignItems: 'center',
      lineHeight: 1,

      '&:hover': {
        bg: Color(brand[0]).darken(.3).string()
      }
    },
    secondary: {
      variant: 'buttons.brand',
      color: 'background',
      bg: 'secondary'
    },
    image: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'transparent',
      padding: 0,
      width: 'auto',
      minWidth: 'initial'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    }
  }
}

export default theme
